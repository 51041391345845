import React from 'react'

const StepBox = (props) => {
	return (
		<div className="step-section">
			<div className="step-num-section">
				<div
					className="step-text"
					dangerouslySetInnerHTML={{ __html: props.text }}
				></div>
			</div>
			<div className="step-image-section">
				<div className="step-image">
					<img src={props.image} alt="" />
				</div>
			</div>
		</div>
	)
}

export default StepBox
