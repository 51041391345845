import React, { useState, useEffect } from 'react'

const ARCamera = () => {

	useEffect(() => {
		document.body.classList.add('camera-page')
		return () => {
			document.body.classList.remove('camera-page')
		}
	}, [])


	return (
		<div className="page-arcamera">
		</div>
	)
}

export default ARCamera
