import React from 'react'

const Footer = (props) => {
	return (
		<div className="footer-section">
            <div><img className="footer-image" src={props.image} alt="" /></div>
        </div>
	)
}

export default Footer
