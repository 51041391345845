import React from 'react'

// Components
import Header from '../components/Header'
import Banner from '../components/Banner'
import Button from '../components/Button'
import Footer from '../components/Footer'
import StepBox from '../components/StepBox'

const GameStart = () => {
	return (
		<div className="page-game-start">
			<Header />
			<Banner image={`${process.env.PUBLIC_URL}/assets/images/top-bg-2.png`} />
			<div className="main-content">
				<div className="content-box">
					<div className="content">
						<div className="main-step-box">
							<StepBox
								text="步驟一<br/>按下“開始遊戲“按鍵 後把鏡頭對準自已的臉部"
								image={`${process.env.PUBLIC_URL}/assets/images/pic-step-1.png`}
							/>
							<StepBox
								text="步驟二<br/>按下代表冰毒 /大麻 的代表圖案, 即可了解吸食冰毒 /大麻的後遺症"
								image={`${process.env.PUBLIC_URL}/assets/images/pic-step-2.png`}
							/>
						</div>
					</div>
				</div>
				<Button
					to="/ar-camera"
					image={`${process.env.PUBLIC_URL}/assets/images/button-3.png`}
				/>
			</div>
			<Footer image={`${process.env.PUBLIC_URL}/assets/images/bottom-bg.png`} />
		</div>
	)
}

export default GameStart
