import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
	return (
		<div className="header-section">
            <div className="logo-section">
                <div className="caritas-logo">
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo-1.png`} alt="" />
                    </Link>
                </div>
                <div><img src={`${process.env.PUBLIC_URL}/assets/images/logo-2.png`} alt="" /></div>
            </div>
        </div>
	)
}

export default Header
