import React from 'react'
import { Link } from 'react-router-dom'

const Button = (props) => {
	return (
		<div className="button-section" onClick={props.onClick}>
            <Link to={props.to ? props.to : ''}>
                <div><img className="btn-image" src={props.image} alt="" /></div>
            </Link>
        </div>
	)
}

export default Button
