import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

// Components
import Home from '../pages/Home'
import GameGuide from '../pages/GameGuide'
import GameStart from '../pages/GameStart'
import ARCamera from '../pages/ARCamera'

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
        <div className="app">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/game-guide" element={<GameGuide />} />
                <Route path="/game-start" element={<GameStart />} />
                <Route path="/ar-camera" element={<ARCamera />} />
            </Routes>
        </div>
    </Router>
  );
}

export default App;
