import React from 'react'

// Components
import Header from '../components/Header'
import Banner from '../components/Banner'
import Button from '../components/Button'
import Footer from '../components/Footer'

const GameGuide = () => {
	return (
		<div className="page-game-guide">
            <Header />
            <Banner image={`${process.env.PUBLIC_URL}/assets/images/top-bg-2.png`}/>
            <div className="main-content">
				<div className="content-box">
					<div className="content text-center">
						<div className='about-title'>計劃目標</div>
						<p>
							運用VR全景技術、穿戴式情緒追蹤裝置協助協助戒毒者/康復者抗拒毒品誘惑，減低重吸機率。
							協助社工、戒毒者家人了解吸毒者所面對的困難。另外，利用VR模擬吸毒後反應，讓中、小學生理解吸毒後的身體狀況及相關危險。
						</p>

						<div className='about-title'>服務對象</div>
						<p>中/小學生、濫藥者及其家人、曾濫藥者及社工</p>
						
						<div className='about-title'>服務內容</div>
						<p>
							專業輔導：個案輔導 ；家人支援；外展服務
							公眾教育：高危人士 ；學校
						</p>
					</div>
				</div>
                <Button to="/game-start" image={`${process.env.PUBLIC_URL}/assets/images/button-2.png`} />
            </div>
            <Footer image={`${process.env.PUBLIC_URL}/assets/images/bottom-bg.png`}/>
		</div>
	)
}

export default GameGuide
