import React from 'react'

// Components
import Header from '../components/Header'
import Banner from '../components/Banner'
import Button from '../components/Button'
import Footer from '../components/Footer'

const Home = () => {
	return (
		<div className="page-home">
            <Header />
            <Banner image={`${process.env.PUBLIC_URL}/assets/images/top-bg-1.png`}/>
            <div className="main-content">
                <div className="content text-center">
                    <p className='p-pb'>明愛容圃中心成立於一九九六年，中心主要為吸食危害精神毒品之人士提供輔導及支援服務。</p>
                    <p>「容圃」兩字是指一塊容納與接受、培育與發展的園地的意思。
                        「容圃」亦來自英文“HUGS”中譯「擁抱」之諧音。
                        秉承我們的口號「擁抱親朋，遠離毒品」的信念，
                        我們希望動員吸毒者家人、朋友的力量，協助他們戒除毒癮，
                        建立及維持一個健康和有意義的生活模式。
                    </p>
                </div>
                <Button to="/game-guide" image={`${process.env.PUBLIC_URL}/assets/images/button-1.png`} />
            </div>
            <Footer image={`${process.env.PUBLIC_URL}/assets/images/bottom-bg.png`}/>
		</div>
	)
}

export default Home
